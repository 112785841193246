export const DUMMY_QUESTIONS = [
    {
        text: 'What is your name?',
        tooltip: 'We need this...',
        type: 'input',
        placeholder: 'e.g. Mark Zuckerberg'
    },
    {
        text: 'What is your age?',
        tooltip: 'We need this...',
        type: 'input',
        placeholder: 'Enter your age',
    },
    {
        text: 'What is your full address?',
        tooltip: 'We need this...',
        type: 'input',
        placeholder: 'Enter your full address here',
    },
    {
        text: 'What is your full address?',
        tooltip: 'We need this...',
        type: 'input',
        placeholder: 'Enter your full address here',
    },
    {
        text: 'Select the choice you feel the strongest about',
        tooltip: 'We need this...',
        type: 'multipleChoice',
        options: ['Option 1', 'Option 2', 'Option 3']
    },
    {
        text: 'Ask a question you want them to be able to have a free response to.',
        tooltip: 'We need this...',
        type: 'input',
        multiline: true,
        placeholder: 'Enter response',
    },
    {
        text: 'Ask a question you want them to be able to have a free response to.',
        tooltip: 'We need this...',
        type: 'range',
        range: {
            min: 0,
            minLabel: 'least',
            max: 10,
            maxLabel: 'most',
            step: 1,
            initial: 5,
        }
    },
    {
        text: 'Select the choice you feel the strongest about',
        tooltip: 'We need this...',
        type: 'rankChoice',
        directions: 'Click on the box next to an option to give it a rank.',
        options: [
            {
                rank: null,
                option: 'Option 1',
            },
            {
                rank: null,
                option: 'Option 2',
            },
            {
                rank: null,
                option: 'Option 3',
            }
        ]
    },
]


export const BRANDING = {
    logo: require('../dummy/assets/surveylogo.png'),
    bg: require('../dummy/assets/surveybg.png'),
    title:'Scope Labs Interest Form',
    completedMessage:'  We appreciate you taking the time to tell us more about your idea. We will reach out shortly with next steps.',
    helpMessage:'Should you have any questions or need to follow up you may do so at',
    helpEmail:'hello@scopelabs.com',
    styles: {
        color: '#D561D8',
        bgColor:'#000000',
        buttonRadius: 'rounded-[0px]',
    },
    backUrl: "https://scopelabs.com/zero-to-one"
}
