import React, {Component} from "react";


class RankChoiceResponseItem extends Component {
    state = {
        selected: false,
        rank: null
    }

    setRank = () => {
        if (this.state.rank) {
            this.setState({rank: null})
        } else {
            this.setState({rank: 1})
        }
    }

    render() {
        const option = this.props.option
        const selected = this.state.selected
        return (
            <div className={`flex flex-row items-stretch gap-2`} key={option.option}>
                <div
                    onClick={this.setRank}
                    className={`
                    relative rank-container
                    ${!this.state.rank && 'empty-rank-container'}
                    border-2 rounded-xl border-slate-300 flex flex-col items-center justify-center w-14 
                    ${this.state.rank ? 'border-blue-500 bg-indigo-50 hover:text-red-400 cursor-pointer' : 'cursor-pointer hover:bg-slate-50 hover:border-indigo-300'}`}>
                    {this.state.rank ?
                        <>
                            <p className='text-xl font-medium'>
                                1
                            </p>
                        </>
                        :
                        <p className='text-xs text-center text-slate-500'>
                            Click to rank
                        </p>
                    }
                </div>
                <div
                    onClick={() => this.setState({selected: true})}
                    className={`p-5 border-2 border-slate-100 flex-1 rounded-xl`}
                >
                    <p className={'text-xl'}>
                        {option.option}
                    </p>
                </div>
            </div>
        )
    }
}

export default RankChoiceResponseItem
