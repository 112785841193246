import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getStorage, ref, uploadBytes, getDownloadURL} from 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyC0T5E08yCb4gtoLhNCzc2jZZ2wT4AeB58",
  authDomain: "survey-demo-4586e.firebaseapp.com",
  projectId: "survey-demo-4586e",
  storageBucket: "survey-demo-4586e.appspot.com",
  messagingSenderId: "236081793958",
  appId: "1:236081793958:web:adcc312a3d3b29fa929d67",
  measurementId: "G-TS9XCC0FYX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const firebaseStorage = getStorage(app);

export const uploadDoc = async (folder, doc_id, doc) => {
  const docRef = ref(firebaseStorage, `${folder}/${doc_id}`);
  const snapshot = await uploadBytes(docRef, doc);

  const docURL = await getDownloadURL(snapshot.ref);
  return docURL
};