import React, {Component} from "react";
import uuid from 'react-uuid';
import DropZone from "./DropZone";
import {uploadDoc} from "../api/firebase";


class DropzoneBody extends Component {
    state = {
        text: null,
        files: [],
    }

    addFile = async (files) => {
        const file = files[0]
        const id = uuid();
        const url = await uploadDoc("uploads", id, file);
        file["url"] = url;
        this.setState({files: [...this.state.files, file]})

        const fileUrls = [...this.state.files, file].map(file => file.url);
        this.props.updateAnswer(this.props.index, fileUrls);
    }

    deleteFile = (file) => {
        const updatedFiles = this.state.files.filter(f => f.path !== file.path)
        this.setState({files: updatedFiles})

        const fileUrls = updatedFiles.map(file => file.url);
        this.props.updateAnswer(this.props.i, fileUrls);
    }

    render() {
        return (
            <DropZone
                addedFiles={this.state.files}
                onDrop={this.addFile}
                deleteFile={this.deleteFile}
            />
        )
    }
}

export default DropzoneBody
