import React, {Component} from "react";
import MultipleChoiceResponseItem from "./MultipleChoiceResponseItem";


class MultipleChoiceResponseBody extends Component {
    state = {
        selections: []
    }

    selectOption = (option) => {
        let newSelections = [...this.state.selections];
        if (this.state.selections.includes(option)) {
            const index = this.state.selections.indexOf(option);
            newSelections.splice(index, 1);
        } else {
            newSelections.push(option);
        }
        this.setState({selections: newSelections});

        const i = this.props.index;
        this.props.updateAnswer(i, newSelections);
    }

    render() {
        return (
            <div className='w-full flex flex-col gap-2'>
                {this.props.options.map(option =>
                    <MultipleChoiceResponseItem branding={this.props.branding} option={option} updateAnswer={this.props.updateAnswer} index={this.props.index}/>
                )}
            </div>
        )
    }
}

export default MultipleChoiceResponseBody
