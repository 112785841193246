import {Component} from "react";

class QuestionInnerContainer extends Component {
    render() {
        return (
            <div
                className='flex flex-col gap-5 w-full'>
                {this.props.children}
            </div>
        )
    }
}

export default QuestionInnerContainer;
