import './App.css';
import SurveyBody from "./pages/SurveyBody";

function App() {
    return (
        <SurveyBody/>
    );
}

export default App;
