import axios from './axios';

export const submitSurvey = async (template_id, answers) => {
    // if (!user) {
    //     return
    // }
    // const token = await user.getIdToken();
    try {
        // const config = {
        //     headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
        // };
        const res = await axios.post("/responses", {template_id, answers});
        return res.data;
    } catch (err) {
        console.log(err);
    }
}