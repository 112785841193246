import React, {Component} from "react";
import Button from "./atoms/Button";
import {motion} from "framer-motion";

class CompletedStep extends Component {
    goBack = () => {
        window.location.href = this.props.branding.backUrl;
    }

    render() {
        const branding = this.props.branding
        return (
            <motion.div
                initial={{translateX: 40, opacity:0}}
                animate={{translateX: 0, opacity:1}}
                transition={{ duration: 0.3, ease: "easeOut" }}
                className='w-full px-5 sm:w-[50vw] md:w-[40vw] lg:w-[35vw] flex flex-row gap-3 items-center absolute'>
                <div className='flex flex-col items-center text-center gap-5 w-full'>
                    <p className='text-slate-800 text-2xl'>
                        Thank you for your response!
                    </p>
                    <p className='text-lg text-slate-500'>
                        {branding.completedMessage}
                    </p>
                    <p className='text-lg text-slate-500 mb-5'>
                        {branding.helpMessage}
                        <a className='font-medium cursor-pointer' style={{color: branding.styles.color}} href={`mailto: ${branding.helpEmail}`}>
                            &nbsp;{branding.helpEmail}
                        </a>.
                    </p>
                    <Button
                        color={branding.styles.color}
                        className={`${branding.styles.buttonRadius}`}
                        onClick={this.goBack}
                    >
                        Go back
                    </Button>
                </div>
            </motion.div>
        )
    }
}

export default CompletedStep
