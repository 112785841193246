import React, {Component} from "react";
import SvgArrowRight from "../icons/SvgArrowRight";


class QuestionNumberDisplay extends Component {
    render() {
        return (
            <div className='flex flex-row items-center gap-2 mt-0.5'>
                <p className='text-slate-800 text-xl mb-0.5'>
                    {this.props.number}
                </p>
                <SvgArrowRight className={'w-6 h-6'}/>
            </div>
        )
    }
}

export default QuestionNumberDisplay
