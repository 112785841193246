import React, {Component} from "react";
import MultipleChoiceResponseItem from "./MultipleChoiceResponseItem";
import RankChoiceResponseItem from "./RankChoiceResponseItem";


class RankChoicesResponseBody extends Component {
    render() {
        return (
            <div className='w-full flex flex-col gap-2'>
                {this.props.options.map(option =>
                    <RankChoiceResponseItem option={option}/>
                )}
            </div>
        )
    }
}

export default RankChoicesResponseBody
