import axios from './axios';

export const getTemplate = async (template_id) => {
    // if (!user) {
    //     return
    // }
    // const token = await user.getIdToken();
    try {
        const config = {
            // headers: {Authorization: `Bearer ${token}`, mode: "no-cors", 'Access-Control-Allow-Origin': "*"},
            params: {template_id}
        };
        const res = await axios.get("/templates", config);
        return res.data;
    } catch (err) {
        console.log(err);
    }
}
