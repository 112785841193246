import {Component} from "react";
import {wait} from "@testing-library/user-event/dist/utils";

class Button extends Component {
    componentDidMount() {
        wait(100).then(() => {
            this.switchVariant()
        })
    }

    switchVariant = () => {
        if (this.props.color) {
            return `hover:opacity-80 active:opacity-80 outline outline-transparent ${!this.props.disabled ? 'hover:outline-slate-300' : ''} outline-offset-2`
        } else {
            switch (this.props.variant) {
                case 'secondary':
                    return 'bg-slate-100 hover:bg-slate-200 active:bg-slate-300 text-slate-800'
                default:
                    return 'bg-indigo-600 hover:bg-indigo-700 active:bg-indigo-800 text-white'
            }
        }
    }

    render() {
        return (
            <button
                onClick={this.props.onClick}
                disabled={this.props.disabled}
                className={`flex flex-row gap-2 h-14 disabled:opacity-50 px-8 text-xl font-medium items-center ${this.switchVariant()} ${!this.props.disabled && "cursor-pointer"} ${this.props.className}`}
                style={{
                    backgroundColor: this.props.color,
                    color: 'white'
                }}
            >

                {this.props.children}
            </button>
        )
    }
}

export default Button;
