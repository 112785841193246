import React, {Component} from "react";


class TextResponseBody extends Component {
    constructor(props) {
        super(props);
        this.state = {text: null};
        this.inputRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.isActiveStep && this.props.isActiveStep) {
            if (this.inputRef.current) {
                this.inputRef.current.focus();
            }
        }
    }

    render() {
        if (this.props.multiline) {
            return (
                <div>
                    <textarea
                        ref={this.inputRef}
                        value={this.state.text}
                        onChange={(e) => {
                            this.props.updateAnswer(this.props.index, e.target.value);
                            this.setState({text: e.target.value})
                        }}
                        className='bg-slate-50 min-h-[160px] md:min-h-[120px] w-full border-b-2 border-b-slate-200 focus:border-indigo-600 text-xl p-3'
                        placeholder={this.props.placeholder}
                    />
                </div>
            )
        }
        return (
            <div className='w-full'>
                <input
                    ref={this.inputRef}
                    value={this.state.text}
                    onChange={(e) => {
                        this.props.updateAnswer(this.props.index, e.target.value);
                        this.setState({text: e.target.value})
                    }}
                    className='bg-slate-50 w-full border-b-2 border-b-slate-200 focus:border-indigo-600 text-xl'
                    placeholder={this.props.placeholder}
                />
            </div>
        )
    }
}

export default TextResponseBody
