import {Component} from "react";

class QuestionContainer extends Component {
    render() {
        return (
            <div
                className='w-[90vw] sm:w-[70vw] md:w-[60vw] lg:w-[40vw] flex flex-row gap-3 items-start absolute'
                style={this.props.style}
            >
                {this.props.children}
            </div>
        )
    }
}

export default QuestionContainer;
