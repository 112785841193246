import {Component} from "react";
import {Range} from "react-range";

class RangeResponseBody extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentValue: this.props.question.range.initial,
        };
    }

    handleSliderChange = (event) => {
        this.setState({currentValue: event});

        // For example, this.props.onSliderChange(newValue);
    };

    generateValuesArray = () => {
        const {min, max, step} = this.props.question.range;
        const valuesArray = [];

        for (let i = min; i <= max; i += step) {
            valuesArray.push(i);
        }

        return valuesArray;
    };

    render() {
        const range = this.props.question.range
        const valuesArray = this.generateValuesArray();
        return (
            <div>
                <Range id="steps-range"
                       type="range"
                       min={range.min}
                       max={range.max}
                       values={[this.state.currentValue]}
                       step={range.step}
                       onChange={this.handleSliderChange}
                       renderTrack={({props, children}) => (
                           <div
                               {...props}
                               className='w-full h-5 bg-slate-200 rounded-full appearance-none cursor-pointer'>
                               {children}
                           </div>
                       )}
                       renderThumb={({props}) => (
                           <div
                               {...props}
                               className='bg-indigo-600 h-8 w-8 rounded-full border-2 border-white'
                           />
                       )}
                />
                <div className='flex flex-col mt-5 gap-2'>
                    <div className='flex flex-row items-center justify-between text-lg text-slate-500'
                         style={{margin: '0 -8px 0 -2px'}}>
                        <p className=''>{range.min}</p>
                        <p className=''>{range.max / 2}</p>
                        <p className=''>{range.max}</p>
                    </div>
                    <div className='flex flex-row items-center justify-between text-xl text-slate-500'>
                        <p className='capitalize'>
                            {range.minLabel}
                        </p>
                        <p className='capitalize'>
                            {range.maxLabel}
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

export default RangeResponseBody;
