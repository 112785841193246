import {Component} from "react";
import TextResponseBody from "../TextResponseBody";
import MultipleChoiceResponseBody from "../MultipleChoiceResponseBody";
import RankChoicesResponseBody from "../RankChoicesResponseBody";
import RangeResponseBody from "../RangeResponseBody";
import DropzoneBody from "../DropzoneBody";

class QuestionDisplayWrapper extends Component {
    render() {
        const question = this.props.question;
        return (
            <>
                {question.type === 'input' &&
                    <TextResponseBody
                        placeholder={question.placeholder}
                        multiline={question.multiline}
                        updateAnswer={this.props.updateAnswer}
                        index={this.props.index}
                        isActiveStep={this.props.isActiveStep}
                    />
                }
                {question.type === 'multipleChoice' &&
                    <MultipleChoiceResponseBody
                        options={question.options}
                        updateAnswer={this.props.updateAnswer}
                        index={this.props.index}
                        branding={this.props.branding}
                    />
                }
                {question.type === 'range' &&
                    <RangeResponseBody
                        question={question}
                        updateAnswer={this.props.updateAnswer}
                        index={this.props.index}

                    />
                }
                {question.type === 'rankChoice' &&
                    <RankChoicesResponseBody
                        options={question.options}
                        updateAnswer={this.props.updateAnswer}
                        index={this.props.index}
                    />
                }
                {question.type === 'dropzone' &&
                    <DropzoneBody
                        updateAnswer={this.props.updateAnswer}
                        index={this.props.index}
                    />
                }
            </>
        )
    }
}

export default QuestionDisplayWrapper;
