import React, {Component} from "react";


class QuestionDisplay extends Component {
    render() {
        return (
            <div>
                <p className='text-slate-800 text-2xl'>
                    {this.props.text}
                </p>
                {!!this.props.subText &&
                    <p className='text-md text-slate-500 mt-2'>
                        {this.props.subText}
                    </p>
                }
            </div>
        )
    }
}

export default QuestionDisplay
