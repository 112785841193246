import React, {Component} from "react";


class MultipleChoiceResponseItem extends Component {
    state = {
        selected: false
    }

    render() {
        const option = this.props.option
        const selected = this.state.selected
        return (
            <div key={option}
                 onClick={(e) => {this.setState({selected: true}); this.props.updateAnswer(this.props.index, e.target.outerText)}}
                 className={`p-5 border-2 border-slate-300 ${selected ? 'border-blue-600 bg-indigo-50' : 'cursor-pointer hover:bg-slate-50 hover:border-indigo-300'} rounded-xl ${this.props.branding.styles.buttonRadius}`}
            >
                <p className={'text-xl'}>
                    {option}
                </p>
            </div>
        )
    }
}

export default MultipleChoiceResponseItem
