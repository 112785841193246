import QuestionNumberDisplay from "../components/QuestionNumberDisplay";
import QuestionDisplay from "../components/QuestionDisplay";
import {Component} from "react";
import {wait} from "@testing-library/user-event/dist/utils";
import QuestionContainer from "../components/wrappers/QuestionContainer";
import QuestionInnerContainer from "../components/wrappers/QuestionInnerContainer";
import {BRANDING, DUMMY_QUESTIONS} from "../dummy/dummy";
import QuestionDisplayWrapper from "../components/wrappers/QuestionDisplayWrapper";
import Button from "../components/atoms/Button";
import {getTemplate} from "../api/templates";
import {submitSurvey} from "../api/responses";
import SvgReplay from "../icons/SvgReplay";
import SvgChevronLeft from "../icons/SvgChevronLeft";
import CompletedStep from "../components/CompletedStep";
import LogoMark from "../components/svgs/LogoMark";

const numSteps = 20;

const TEMPLATE_ID = "mvp-splash-page";

const opacityStateVariables = Array.from({length: numSteps}, (_, index) => `opacityStep${index + 1}`);
const translationStateVariables = Array.from({length: numSteps}, (_, index) => `translationStep${index + 1}`);

class SurveyBody extends Component {
    constructor(props) {
        super(props);

        // Initialize the state with dynamic values
        const initialState = {
            step: 1,
            questions: DUMMY_QUESTIONS,
            answers: {},
        };

        for (let i = 1; i <= numSteps; i++) {
            initialState[`opacityStep${i}`] = i === 1 ? 1 : 0; // Set opacityStep1 to 1, others to 0
            initialState[`translationStep${i}`] = i === 1 ? 0 : 20; // Set translationStep1 to 0, others to 20
        }

        this.state = initialState;
    }

    navigateToStep = async (step) => {
        const currentStep = this.state.step
        const movingForwards = step > currentStep
        this.setState(
            {[`opacityStep${currentStep}`]: 0, [`translationStep${currentStep}`]: movingForwards ? -20 : 20},
            () => {
                wait(300)
                    .then(() => {
                        this.setState(
                            {step},
                            () => wait(150)
                                .then(() => this.setState({[`opacityStep${step}`]: 1, [`translationStep${step}`]: 0}))
                        )
                    })
            }
        )

        if (currentStep === this.state.questions.length) {
            await submitSurvey(TEMPLATE_ID, this.state.answers);
        }
    }

    updateAnswer = (i, text) => {
        const answers = {...this.state.answers};
        answers[i] = text;
        this.setState({answers});
    }

    componentDidMount = async () => {
        // Comment this out if you want the dummy questions
        const res = await getTemplate(TEMPLATE_ID);
        this.setState({questions: res.template.questions});
    }

    goBack = () => {
        window.location.href = BRANDING.backUrl;
    }

    render() {
        const progressWidth = (this.state.step / this.state.questions.length) * 100;
        const branding = BRANDING
        return (
            <div className='flex flex-col bg-white h-[100dvh] flex flex-col items-center justify-center'
                // style={{backgroundColor:branding.styles.bgColor}}
            >
                <div className='absolute top-[10vh] right-2 md:right-[5vw]'>
                    <img src={branding.bg} alt={'brand'} className='w-[20vw] object-contain opacity-20'/>
                </div>
                <div className='flex flex-row p-3 md:p-8 justify-between items-center gap-10 w-full'>
                    <button className='text-slate-500 hover:text-slate-800 w-20 flex flex-row items-center gap-2'
                            onClick={this.goBack}>
                        <SvgChevronLeft/>
                        <p className={'text-sm whitespace-nowrap'}>
                            Back
                        </p>
                    </button>
                    <div className='flex flex-col gap-2'>
                        <img src={branding.logo} className='w-[120px] md:w-[160px] h-[60px] object-contain'/>
                    </div>
                    <div className='w-20'/>
                </div>
                <div className='flex flex-col flex-1 px-5 w-full flex flex-col items-center'>
                    <div className='bg-slate-200 h-3 top-12 w-full md:w-[40vw] rounded-full overflow-hidden '>
                        <div className='h-3'
                             style={{
                                 width: progressWidth + '%',
                                 transition: 'width 600ms ease-in-out',
                                 backgroundColor: branding.styles.color
                             }}/>
                        {progressWidth}
                    </div>
                    <div className='flex flex-col items-center justify-center flex-1 mt-[-60px]'>
                        {this.state.questions.map((question, index) =>
                            <QuestionContainer style={{
                                opacity: this.state[opacityStateVariables[index]],
                                transform: `translateX(${this.state[translationStateVariables[index]]}px)`,
                                transition: '300ms ease-in-out',
                                zIndex: (index + 1) === this.state.step ? 100 : 0
                            }}>
                                <QuestionNumberDisplay number={index + 1}/>
                                <QuestionInnerContainer>
                                    {index !== 0 &&
                                        <div
                                            className='flex text-md font-medium flex-row items-center text-slate-500 hover:text-slate-800 cursor-pointer gap-2 absolute top-[-32px]'
                                            onClick={() => this.navigateToStep(this.state.step - 1)}>
                                            <SvgReplay className={'w-5 h-5'}/> Previous
                                        </div>
                                    }
                                    <QuestionDisplay
                                        text={question.text}
                                        tooltip={question.tooltip}
                                        subText={question.directions}
                                    />
                                    <QuestionDisplayWrapper
                                        question={question}
                                        updateAnswer={this.updateAnswer}
                                        index={index}
                                        branding={branding}
                                        isActiveStep={(index + 1) === this.state.step }
                                    />
                                    <div className='flex flex-row items-center gap-2'>
                                        <Button onClick={() => this.navigateToStep(this.state.step + 1)}
                                                disabled={!this.state.answers[this.state.step - 1] && !this.state.questions[this.state.step - 1]?.optional}
                                                color={branding.styles.color}
                                                className={`${branding.styles.buttonRadius}`}
                                        >
                                            {this.state.step < this.state.questions.length ? "Next" : "Submit"}
                                        </Button>
                                    </div>
                                </QuestionInnerContainer>
                            </QuestionContainer>
                        )}
                        {this.state.step === (this.state.questions.length + 1) &&
                            <CompletedStep
                                branding={branding}
                            />
                        }
                    </div>
                </div>
                <div
                    className='flex py-3 px-8 gap-1 md:gap-2 flex-row items-center text-white/50 justify-center border-t bg-neutral-800 w-full'>
                    <p className='text-xs md:text-sm'>
                        Powered by
                    </p>
                    <div className='flex flex-row items-center gap-1'>
                        <LogoMark className={'h-4 md:h-5 w-24 md:w-28'}/>
                        {/*<SvgArrowRight className={'w-4 h-4 rotate-[-45deg]'}/>*/}
                    </div>
                </div>
            </div>
        )
    }
}

export default SurveyBody;
